<template lang="pug">
.slots-actions
  .slots-actions__main
    span.slots-actions__last-reload
      div.slots-actions__last-reload-title Last update:
      div.slots-actions__last-reload-subtitle TOPCBR - 
        span {{ lastReload.top }}
      div.slots-actions__last-reload-subtitle MIJNCBR - 
        span {{ lastReload.mijn }}

  .slots-actions__additional
    portal-target(name="slots-type-choosing")
    .slots-actions__btns
      v-btn(outlined @click="reloadTOP" :loading="loadingTOP").btn-common.mr-2 reload TOPCBR slots
      v-btn(outlined @click="reloadMIJN" :loading="loadingMIJN").btn-common reload MIJNCBR slots
</template>

<script>
import {mapMutations, mapGetters} from "vuex"

import errorsMixin from '@/mixins/errors.mixin'
import { convertToDefaultDateHourFormat } from '@/util'

export default {
  mixins: [errorsMixin],
  data: () => ({
    lastReload: {
      top: null,
      mijn: null
    },
    loadingTOP: false,
    loadingMIJN: false
  }),
  inject: ['svc'],

  computed: {
    ...mapGetters({
      _activeType: 'slotsType/value'
    }),

    activeType: {
      get() {
        return this._activeType
      },
      set (newType) {
        return this.setActiveType(newType)
      }
    }
  },
  mounted() {
    this.loadLastRelaod()
  },

  methods: {
    ...mapMutations({
      setActiveType: "slotsType/set"
    }),

    async loadLastRelaod() {
      try {
        let {top, mijn} = await this.svc.getLastReloadTime(this.$route.query)
        this.lastReload.top = convertToDefaultDateHourFormat(top)
        this.lastReload.mijn = convertToDefaultDateHourFormat(mijn)
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async reloadTOP() {
      try {
        this.loadingTOP = true
        let res = await this.svc.reloadTOP()
        this.loadingTOP = false
        this.$notify({text: 'Start reloading TOPCBR slots', type: 'success' })
        return res
      } catch (error) {
        this.loadingTOP = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async reloadMIJN() {
      try {
        this.loadingMIJN = true
        let res = await this.svc.reloadMIJN()
        this.$notify({text: 'Start reloading MIJNCBR slots', type: 'success' })
        this.loadingMIJN = false
        return res
      } catch (error) {
        this.loadingMIJN = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.slots-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 14px;
  padding-right: 14px;
  padding-left: 14px;
  &__main {
    display: flex;
  }
  &__additional {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
  &__last-reload {
    padding-left: 0;
    &-title {
      font-weight: 500;
      font-size: 14px;
    }
    &-subtitle {
      color: $label-color;
      font-size: 14px;
      & span {
        color: #000000;
      }
    }
  }
  &__btns {
    .v-btn {
      margin-top: 10px;
      @media (min-width: 992px) {
        margin-top: 0;
      }
    }
  }
}

</style>